.dowpage {
    width: 60%;
    background-color: #fff;
    box-shadow: 0 0.27273rem 0.12727rem -0.12727rem rgba(0, 0, 0, 0.1);
    margin: 20px auto 0;
    height: 100px;
    border-radius: 10px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // 添加背景水印图片

    // background-image: url('https://wtplugwt.oss-cn-hangzhou.aliyuncs.com/webimages/logo512.png');
    // background-size: 20px 20px;
    position: relative; // 新增定位上下文
    overflow: hidden; // 防止旋转内容溢出

    // 添加水印层（使用伪元素实现）
    &::before {
        content: '';
        position: absolute;
        top: -150%;
        left: -150%;
        right: -150%;
        bottom: -150%;
        background-image: url('https://wtplugwt.oss-cn-hangzhou.aliyuncs.com/webimages/logo512.png');
        background-size: 40px 40px; // 包含间距的尺寸（原图20px + 间距20px）
        opacity: 0.1; // 调整水印透明度
        transform: rotate(25deg); // 旋转角度
        z-index: 1;
        pointer-events: none; // 防止点击穿透
        height: 600px;
    }

    .titern {
        // 原有样式
        position: relative; // 确保内容在上层
        z-index: 2; // 高于水印层
    }


    .titern {
        text-align: left;
    }

    .iconetr {
        color: #4DCC8E;
    }
}