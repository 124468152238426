
.dowIcnt{
    width: 400px;
    height: 60px;
    // border: 1px solid black;
    border-radius: 30px;
    // overflow: hidden;
    background-color: #fff;
    margin:50px auto 105px; 
}
.dowiconboxst {
    display: flex;
    width: 400px;
    height: 60px;
    border-radius: 30px;
    overflow: hidden;
    background-color: #fff;
    margin:-25px auto 130px;
    align-items: center;
    // justify-content: space-between;
    color: #000;
    // color: #ff6262;
    box-shadow:  22px 22px 44px #cecfd0,
     -22px -22px 44px #ffffff;
     transition: all 0.1s ease-in-out;
    cursor: pointer;
 
    .iconbiox {
        width: 60px;
        height: 60px;
        .iconmobxconte {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .dowtitlecont {
        font-size: 24px;
    }
    .dowiconbx {
        padding: 0 20px;
        font-size: 24px;
    }
}
.Outt{
    font-size: 14px;
    font-weight: 900;
    background-color: #ff6262;
    color: #eeeeee;
    width: 120px; 
    border-radius: 20px;
    z-index: 999;
    text-align: center;
    height: 25px;
    line-height: 25px;
    margin: 0 auto;
    margin-right: 420px;
    position: relative;
    top: -10px;
   left: 320px;
   
}
.Out{
 font-size: 14px;
 font-weight: 900;
 background-color: #ff6262;
 color: #eeeeee;
 width: 120px;
//  margin-left: 400px;
// float: right;
 border-radius: 20px;
 z-index: 999;
 text-align: center;
 height: 25px;
 line-height: 25px;
 margin: 0 auto;
 margin-right: 420px;
 position: relative;
 top: -10px;
left: 200px;
//  margin-bottom: -200px;
// margin: -200px 0 150px 650px;
}
.dowIcn{
    width: 280px;
    height: 60px;
    // border: 1px solid black;
    border-radius: 30px;
    // overflow: hidden;
    background-color: #fff;
    margin:50px auto 105px; 
}
.dowIcnt:hover {
    transform: scale(1.1); 
}
.dowIcn:hover {
    transform: scale(1.1); 
}
div.auxo-modal-footer{
    padding: 0px;
}
.dowiconboxss {
    display: flex;
    width: 280px;
    height: 60px;
    border-radius: 30px;
    overflow: hidden;
    background-color: #fff;
    margin:50px auto 105px;
    align-items: center;
    // justify-content: space-between;
    color: #000;
    // color: #ff6262;
    box-shadow:  22px 22px 44px #cecfd0,
     -22px -22px 44px #ffffff;
     transition: all 0.1s ease-in-out;
    cursor: pointer;
    // &:hover {
    //     transform: scale(1.1);
        
    //     .dowtitlecont {
    //         color: #000;
    //     }
    //     .dowiconbx {
    //         color: #000;
    //     }
    // }
    .iconbiox {
        width: 60px;
        height: 60px;
        .iconmobxconte {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .dowtitlecont {
        font-size: 24px;
    }
    .dowiconbx {
        padding: 0 20px;
        font-size: 24px;
    }
}
.dowiconboxs {
    display: flex;
    width: 280px;
    height: 60px;
    border-radius: 30px;
    overflow: hidden;
    background-color: #fff;
    margin:-25px auto 130px;
    align-items: center;
    // justify-content: space-between;
    color: #000;
    // color: #ff6262;
    box-shadow:  22px 22px 44px #cecfd0,
     -22px -22px 44px #ffffff;
     transition: all 0.1s ease-in-out;
    cursor: pointer;
    // &:hover {
    //     transform: scale(1.1);
        
    //     .dowtitlecont {
    //         color: #000;
    //     }
    //     .dowiconbx {
    //         color: #000;
    //     }
    // }
    .iconbiox {
        width: 60px;
        height: 60px;
        .iconmobxconte {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .dowtitlecont {
        font-size: 24px;
    }
    .dowiconbx {
        padding: 0 20px;
        font-size: 24px;
    }
}
.asimg{
    height:200px;
    margin:0 auto
}
@media screen and (min-width: 500px) {
    .asimg {
        height:450px;
        // margin:0 auto
    } }
 
.reboticom{
    // border: 5px solid black;
     height:300px; 
}
@media screen and (min-width: 500px) {
    .reboticom {
        height: 550px;
    } }
.btnary{
    margin-top: 5px;
}

@media screen and (min-width: 500px) {
    .btnary {
        margin-top: 13px;
    } }
.tabsd {
width: 100%;
}
@media screen and (min-width: 500px) {
.tabsd {
 width: 83%;
} }
.dynamicScrollbar::-webkit-scrollbar{
    width: 0px;
    height: 10px;
}
.dynamicScrollbar {
 height: 500px;
 overflow: auto;
 
 &::-webkit-scrollbar {
   --el-scrollbar-opacity: 0.3;
   --el-scrollbar-bg-color: var(--el-text-color-secondary);
   --el-scrollbar-hover-opacity: 0.5;
   --el-scrollbar-hover-bg-color: var(--el-text-color-secondary);
 }

 &::-webkit-scrollbar-thumb {
   position: relative;
   display: block;
   width: 0;
   height: 0;
   cursor: pointer;
   border-radius: 10px;
   background: rgba($color: #909399, $alpha: 0.2);
   transition: var(--el-transition-duration) background-color;
   opacity: var(--el-scrollbar-opacity, 0.3);
 }
} 


.Home {
    // margin-top: -120px;
    height: 100%;
    // padding-top: 100px;
    .herderbox {
        // position: fixed;
        // top: 0;
        // width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 5px;
        background-color: #fff;
        color: #000;
        align-items: center;
        // border-bottom: #7979792c 1px solid;
        // 下边框阴影25个像素
        // box-shadow: 0px 25px 0px rgba(0, 0, 0, 0.788);
        box-shadow: 0px 15px 7px -7px rgba(0, 0, 0, 0.1);
        .bojhher {
            display: flex;
            justify-content: space-between;
            max-width: 1200px;
            width: 100%;
            flex-wrap: wrap;
        }
        .leftbox {
            display: flex;
            .lefutericon {
                width: 50px;
                height: 50px;
               overflow: hidden;
               .iconimag {
                   width: 100%;
                   height: 100%;
                   object-fit: cover;
               }
            }
            .rightname {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .namejh {
                    font-weight: 900;
                    margin: 0 10px 0 20px;
                    // color: #fff;
                }
                .rightdiscoup {
                    position: relative;
                    .vishops {
                        position: absolute;
                        right: -100px;
                        top: -20px;
                        // font-size: 12px;
                        // transform: translate(-16px, -16px);
                    }
                }
                
            }
        }
        .rightbox {
            display: flex;
            .iconouhte {
                height: 50px;
    line-height: 50px;
                cursor: pointer;
                margin-left: 10px;
                transition: all 0.3s;
                &:hover{
                    color: #409EFF;
                }
                
            }
            .funinfouer {
                a {
                    color: #000 !important;
                    &:hover {
                        color: #409EFF !important;
                    }
                }
                .animated {
                    animation-duration: 0.5s;
                    animation-fill-mode: both;
                }
                
                
                
                .slideInLeft {
                    animation-name: slideInLeft;
                }

                @keyframes slideInLeft {
                    0% { transform: translateX(-100%); opacity: 0; }
                    100% { transform: translateX(0); opacity: 1; }
                }
            }
            .rightuser {
                position: relative;
                &:hover .showinfos{
                    display: block;
                }
                .showinfos {
                    display: none;
                    position: absolute;
                    right: -111px;
                    top: 50px;
                    z-index: 99999999999999999999999;
                    &:hover{
                        display: block;
                    }
                }
            }
        }
       
    }
    .contentainbox {
        // min-height: 1000px;
        // padding: 0 400px;
        max-width: 1200px;
            width: 100%;
            margin: 0 auto;
            padding: 0 5px;
        .titlecontent {
            padding: 120px 0 0 0 ;
            .tibtitle {
                font-size: 47px;
                font-weight: 900;
                margin-bottom: 0;
            }
            .tibtitleconter {
                font-size: 16px;
                color: #909399;
            }
            .dowiconbox {
                display: flex;
                width: 320px;
                height: 60px;
                border-radius: 30px;
                overflow: hidden;
                background-color: #fff;
                margin: 80px auto 0;
                align-items: center;
                justify-content: space-between;
                color: #000;
                box-shadow:  22px 22px 44px #cecfd0,
                 -22px -22px 44px #ffffff;
                 transition: all 0.1s ease-in-out;
                cursor: pointer;
                &:hover {
                    transform: scale(1.1);
                    
                    .dowtitlecont {
                        color: #000;
                    }
                    .dowiconbx {
                        color: #000;
                    }
                }
                .iconbiox {
                    width: 60px;
                    height: 60px;
                    .iconmobxconte {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .dowtitlecont {
                    font-size: 24px;
                }
                .dowiconbx {
                    padding: 0 20px;
                    font-size: 24px;
                }
            }
            .crolist {
                display: flex;
                flex-wrap: wrap;
                .intoulist {
                    // width: 320px;
                    // height: 200px;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 10px;
                    margin: 10px auto 0;
                    box-shadow:  22px 22px 44px #cecfd0,
                     -22px -22px 44px #ffffff;
                     cursor: pointer;
                     transition: all 0.1s ease-in-out;
                     &:hover {
                        transform: scale(1.1);
                        
                        .dowtitlecont {
                            color: #000;
                        }
                        .dowiconbx {
                            color: #000;
                        }
                    }
                     .iconimagebox {
                        width: 60px;
                        height: 60px;
                        margin: 0 auto;
                     }
                    .iconmobxconte {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }
                    // 鼠标移动整体放大1.1
                   
                    .dowtitlecont {
                        font-size: 24px;
                        color: #888;
                        padding: 20px 0;
                    }
                }
            }
        }
        .funinfobox {
            .unger {
                font-weight: 900;
            }
            .yansgconte {
                margin: 20px 0 60px;
                .funitembox {
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 10px;
                    // margin: 20px auto 0;
                    box-shadow:  22px 22px 44px #cecfd0,
                     -22px -22px 44px #ffffff;
                    //  cursor: pointer;
                    .steps{
                       margin-top:40px;
                    }
                     .titinfo {
                        // border: 10px solid black;
                        text-align: left;
                        // border-bottom: 1px solid #eee;
                        box-shadow: 10px 10px 15px  10px rgba(173, 173, 173, 0.1);
                        font-size: 16px;
                        font-weight: 900;
                        padding-bottom: 10px;
                        color: #000;
                     }
                     .gifboxcunet {
                        // height: 400px;
                        padding-top: 10px;
                     }
                    //  transition: all 0.1s ease-in-out;
                    //  &:hover {
                    //     transform: scale(1.1);
                        
                    //     .dowtitlecont {
                    //         color: #000;
                    //     }
                    //     .dowiconbx {
                    //         color: #000;
                    //     }
                    // }
                }
            }
        }
        .bouyer {
            margin-bottom: 100px;
            // position:absolute;
            // bottom:10px;
            // right:40px
        }
        @media screen and (min-width: 500px) {
        .bouyer {
            margin-bottom: 50px;
            position:absolute;
            bottom:10px;
            right:40px
        }
    }}
    .footerbox {
        color: #fff;
        background-color: #2A2B2B;
        text-align: center;
        padding: 25px;
        .banquan {
            padding: 10px;
        }
    }
    
   
}
.shouwcmye {
    width: 100%;
    .gifbox {
        width: 100%;
    }
    .shipinjiaoc {
        width: 100%;
    }
}
.lockVideoBox {
    margin: 40px auto;
    text-align: center;
}
.conte {
    .minxue {
        width: 30px;
        height: 30px;
    }
    .devicon {
        height: 35px;
    }
    .iderrconter {
        color: red;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    .boterng {
        width: 100%;
        text-align: center;
    }
    .lockvideoinfo {
        text-align: center;
        padding: 10px 0;
    }
}