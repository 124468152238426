.Customerbox {
    width: 300px;
    display: inline-block;
    overflow: hidden;

    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    // margin: 20px auto 0;
    box-shadow: 22px 22px 44px #cecfd0,
        -22px -22px 44px #ffffff;

    .usercodeimage {
        width: 100%;
        height: auto;

        .usercode {
            width: 100%;
            height: auto;
        }
    }
    .boconter {
        text-align: left;
    }
}